@use 'sass:math';

@mixin listreset() {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: inherit;

    & > li {
        margin: 0;
        padding: 0;
    }
}

@mixin clearfix() {
    &:after {
        clear: both;
        content: '';
        display: block;
    }
}

@mixin outerpadding() {
    padding-left: math.div($gutter-mobile, 2);
    padding-right: math.div($gutter-mobile, 2);

    @include tablet() {
        padding-left: $gutter-tablet;
        padding-right: $gutter-tablet;
    }

    @include desktop()
    {
        padding-left: math.div($gutter-desktop, 2);
        padding-right: math.div($gutter-desktop, 2);
    }
}

@mixin outermargin() {
    margin-left: math.div($gutter-mobile, 2);
    margin-right: math.div($gutter-mobile, 2);

    @include tablet() {
        margin-left: $gutter-tablet;
        margin-right: $gutter-tablet;
    }

    @include desktop() {
        margin-left: math.div($gutter-desktop, 2);
        margin-right: math.div($gutter-desktop, 2);
    }
}

@function rem($px) {
    @return math.div($px, $text-size-desktop) * 1rem;
}

@function em($px) {
    @return math.div($px, $text-size-desktop) * 1em;
}

@function vw($px, $base: $base-mobile) {
    @return math.div($px, $base) * 100vw;
}

@function vwmax($px, $base: $base-desktop) {
    @if ($px < 0) {
        @return max($px, vw($px, $base));
    } @else {
        @return min($px, vw($px, $base));
    }
}

@function between($from, $to, $fromWidth: $base-mobile, $toWidth: $base-desktop) {
	$slope: math.div(($to - $from), ($toWidth - $fromWidth));
	$base: $from - $slope * $fromWidth;
	
	@return clamp(#{$from}, calc(#{$base} + #{100vw * $slope}), #{$to});
}

@function vh($px) {
    @return math.div($px, 800px) * 100vh;
}

@function pc($pc) {
    @return $pc * 100%;
}

@mixin sprite($image: false) {
    @if $image {
        background-image: url($image);
    }
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@mixin msprite($image: false) {
    @if $image {
        mask-image: url($image);
    }
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}

// full width background
@mixin fwbg($color: transparent) {
    position: relative;

    &:before {
        position: absolute;
        display: block;
        left: -200%;
        right: -200%;
        top: 0;
        bottom: 0;
        background-color: $color;
        content: '';
    }

    & > * {
        position: relative;
    }
}
