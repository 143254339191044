.ce_rsce_intro {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .text-wrapper {
        display: flex;
        flex-direction: column;
        color: white;

        h2 {
            color: white;
            margin-top: 0;
            margin-bottom: 10px;
        }

        .text {
            margin-top: 10px;
            margin-bottom: 40px;
            
            > * {
                margin: 0;
            }
        }

        a {
            @include link-box();
        }
    }

    .image_container {
        border-radius: 3px;
        @include image-shadow();

        &::after {
            border-radius: 3px;
        }
    }

    @include tablet() {
        display: grid;
        grid-template-columns: 1fr max-content;
        column-gap: vwmax(142px);
    }
}
