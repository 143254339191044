.mod_customnav {
    ul {
        @include listreset();
        display: flex;
        flex-direction: column;
        // flex-wrap: wrap;
        column-gap: 30px;

        a {
            text-decoration: none;
        }
    }

    @include tablet() {
        ul {
            flex-direction: row;
        }
    }
}