#header {
    & > .inside {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        max-width: $max-content-width;
        margin-left: auto;
        margin-right: auto;
    }

    .mod_navigation, .mod_changelanguage {
        display: none;
    }

    @include menu() {
        & > .inside {
            height: vwmax(104px);
        }

        #burger {
            display: none;
        }

        .mod_navigation, .mod_changelanguage {
            display: inherit;
        }
    }
}
