@mixin h1() {
    font-family: var(--default-font);
    font-size: 3.6rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;

    @include tablet() {
        font-size: 4.8rem;
    }
}

@mixin h2() {
    font-family: var(--default-font);
    font-size: 2.8rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;

    @include tablet() {
        font-size: 3.6rem;
    }
}

@mixin h3() {
    font-family: var(--default-font);
    font-size: 2.2rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;

    @include tablet() {
        font-size: 2.8rem;
    }
}

@mixin h4() {
}

@mixin h5() {

}

@mixin h6() {

}

@mixin blocked-headline() {
    margin: 0;
    font-size: 24px;
    letter-spacing: 0.19px;
    line-height: 27.5px;
    background-color: white;
    padding: 14px;
    font-family: var(--font-cond);
    font-weight: 950;
    text-align: center;

    @include tablet() {
        font-size: 33px;
        letter-spacing: 0.26px;
        line-height: 40px;
        padding: 15px 65px;
        text-align: left;
    }
}

@mixin typo() {
    line-height: normal;
    font-weight: 400;
}

@mixin margin-type($type, $margin, $important: false) {
    @if $margin > 1 {
        @if $important {
            margin-#{$type}: #{$margin * 0.5}rem !important;

            @include tablet() {
                margin-#{$type}: #{$margin * 0.75}rem !important;
            }

            @include desktop() {
                margin-#{$type}: #{$margin}rem !important;
            }
        } @else {
            margin-#{$type}: #{$margin * 0.5}rem;

            @include tablet() {
                margin-#{$type}: #{$margin * 0.75}rem;
            }

            @include desktop() {
                margin-#{$type}: #{$margin}rem;
            }
        }
    } @else {
        @if $important {
            margin-#{$type}: #{$margin}rem !important;
        } @else {
            margin-#{$type}: #{$margin}rem;
        }
    }
}

@mixin checker() {
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 10px 10px;
    background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
}

/* paragraph reset */
@mixin pr() {
    p {
        line-height: inherit;
    }

    p:first-child {
        margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}
