#wrapper {
    @include main-padding();
    overflow: hidden;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    // background-color: $gray50;

    #container {
        flex-grow: 1;
    }
}