.ce_rsce_components {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .component-box {
        display: grid;
        grid-auto-flow: row;
        background-color: $textLight;
        padding: 30px;
        gap: 30px;
        // align-items: center;
        // justify-content: center;

        h2 {
            grid-row: 1;
            margin: 0;
        }

        .image_container {
            @include image-shadow();
        }

        .text {
            @include pr();
        }
    }

    @include tablet() {
        .component-box {
            grid-template-columns: max-content 1fr;
            gap: 50px;
            padding: 50px;

            .image_container {
                grid-column: 1;
                grid-row: 1 / span 2;
            }
        }
    }
}
