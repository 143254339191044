.ce_rsce_advantages {
    display: flex;
    flex-direction: column;
    gap: 30px;
    
    .text-wrapper {
        text-align: justify;
        max-width: 648px;
        margin-left: auto;
        margin-right: auto;

        h2 {
            margin-top: 0;
            text-align: center;
        }
    }

    .image-boxes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 41px;
    }

    .image-box {
        width: 200px;
        background-color: $textLight;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 30px 10px;
        box-sizing: border-box;

        p {
            margin: 0;
            text-align: center;
        }
    }

}
