.ce_form #personal-data {
    .formbody {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
    }

    .widget-text {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;

        span.mandatory {
            display: none;
        }

        input {
            border: none;
            padding: 0;
            outline: none;
            background-color: $textLight;
            height: 50px;
            width: 100%;
        }
    }

    .widget-submit {
        button {
            @include red-button();
        }
    }

    @include tablet() {
        .formbody {
            display: grid;
            grid-template-columns: repeat(2, minmax(250px, 500px));
            column-gap: 30px;
        }

        .widget-submit {
            grid-row: 2;
            grid-column: 1 / span 2;
        }
    }
}