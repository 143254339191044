@mixin red-button() {
    border: none;
    border-radius: 3px;
    color: white;
    font-size: 1.6rem;
    font-weight: 700;
    padding: 15px 20px;
    background-color: var(--red);
    cursor: pointer;
    text-decoration: none;

    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
    }
}

.red-button a {
    @include red-button();
}