.ce_form #configurator {
    .formbody {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
    }

    .widget-text, .widget-select {
        min-width: 250px;
        max-width: fit-content;
        padding: 20px;
        background-color: $textLight;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;
        column-gap: 10px;
        text-align: center;

        span.mandatory {
            display: none;
        }

        label {
            grid-column: 1 / span 2;
        }

        input[type="number"] {
            border: none;
            padding: 0;
            outline: none;
            -moz-appearance:textfield;
            background-color: transparent;
            text-align: end;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }

        select {
            grid-column: 1 / span 2;
            appearance: none;
            border: none;
            background-color: transparent;
            text-align: center;
        }

        .unit {
            text-align: start;
        }

        input, select, .unit {
            font-size: 4.8rem;
            font-weight: 700;
        }
    }

    .widget-submit {
        align-self: flex-start;

        button {
            @include red-button();
        }
    }

    @include desktop() {
        .formbody {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: max-content;
        }

        .widget-text, .widget-select {
            padding: 30px;
        }

        .widget-submit {
            grid-column: 1 / span 3;
            grid-row: 2;
        }
    }
}