.mod_configurator_list {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    font-size: 1.8rem;

    .wrapper {
        background-color: $textLight;
        display: flex;
        flex-direction: column;
        padding: 5px 25px 25px 25px;
        width: 100%;
        box-sizing: border-box;
    }

    .radiator-box, .component-box {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 25px;
        row-gap: 15px;
        padding: 20px;
        align-items: center;

        .title {
            grid-column: 1 / -2;
            opacity: 0.5;
        }

        .length, .width, .design {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;

            .legend {
                font-size: 1.6rem;
            }
        }

        .design {
            text-transform: capitalize;
        }

        .price {
            grid-column: 1 / -1;
            width: 100%;
            text-align: center;

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                border-top: 1px solid var(--gray);
                margin-bottom: 3px;
            }
        }

        .delete {
            grid-row: 1;
            grid-column: 3;
            justify-self: flex-end;
            background-color: var(--red);
            width: 32px;
            height: 35px;
            padding: 9px;
            border-radius: 3px;
            box-sizing: border-box;
            cursor: pointer;

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                @include sprite('../../assets/trash.svg')
            }

            &:hover {
                box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
            }
        }
    }

    .component-box {
        background-color: white;
        border-radius: 3px;

        .title {
            grid-column: 1 / -1;
        }
    }

    .summary {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
        align-items: center;
        justify-content: space-between;

        a {
            @include red-button();
        }

        .sum {
            display: flex;
            flex-direction: column;
            gap: 6px;
            
            div {
                display: flex;
                gap: 30px;
                justify-content: space-between;

                .legend {
                    opacity: 0.5;
                    justify-self: flex-end;
                }
            }
        }
    }

    .empty {
        align-self: flex-start;
    }

    @include tablet() {
        .summary {
            flex-direction: row;
        }
    }

    @include desktop() {
        font-size: 2.4rem;

        .radiator-box, .component-box {
            display: grid;
            grid-template-columns: 180px repeat(3, 100px) 1fr 45px;
            column-gap: 25px;

            .title {
                grid-column: 1;
            }
    
            .price {
                grid-column: unset;
                text-align: end;

                &::before {
                    display: none;
                }
            }

            .delete {
                grid-column: 6;
                width: 100%;
                height: 46px;
                padding: 12px;
            }
        }

        .summary {

            .sum {
                margin-right: 150px;
            }
        }
    }

    &.view-only {
        .radiator-box, .component-box {
            .title {
                grid-column: 1 / -1;
            }
        }

        @include desktop() {
            .radiator-box, .component-box {
                grid-template-columns: 180px repeat(3, 100px) 1fr;

                .title {
                    grid-column: 1;
                }
            }
        }
    }
}