@mixin image-shadow {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;

    &::after {
        content: '';
        display: block;
        width: 90%;
        height: 90%;
        position: absolute;
        bottom: -10px;
        right: -10px;
        background-color: var(--gray);
        z-index: -1;
    }
}