/**
 * @font-face definitions
 */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100 900;
  src: url('../../fonts/Montserrat-VariableFont_wght.ttf') format('truetype')
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100 900;
  src: url('../../fonts/Montserrat-Italic-VariableFont_wght.ttf') format('truetype')
}
