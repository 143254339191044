/**
 * Breakpoints are set up to be mobile first.
 */
@mixin breakpoint($breakpoint, $mode: 'min') {
    @if $mode == 'max' {
        @media only screen and (max-width:($breakpoint - 1px)) { @content; }
    } @else {
        @media only screen and (min-width:$breakpoint) { @content; }
    }
}

@mixin tablet($mode: 'min') {
    @include breakpoint($tablet, $mode) { @content; }
}

@mixin desktop($mode: 'min') {
    @include breakpoint($desktop, $mode) { @content; }
}

@mixin wide($mode: 'min') {
    @include breakpoint($wide, $mode) { @content; }
}

@mixin menu($mode: 'min') {
    @include breakpoint($menu, $mode) { @content; }
}

@mixin max-width-breakpoint($mode: 'min') {
    @include breakpoint($max-content-width, $mode) { @content; }
}