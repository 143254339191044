@mixin link-box($fontColor: white, $bgColor: var(--red)) {
    color: $fontColor;
    background-color: $bgColor;
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 14px 20px;
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
    }

    @include tablet() {
        margin-left: unset;
        margin-right: unset;
    }
}