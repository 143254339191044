@use 'sass:math';

@mixin max-width() {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
}

@mixin main-padding() {
    padding-left: $main-padding-mobile;
    padding-right: $main-padding-mobile;

    @include desktop() {
        padding-left: $main-padding-desktop;
        padding-right: $main-padding-desktop;
    }
}

@mixin main-margin() {
    margin-left: $main-padding-mobile;
    margin-right: $main-padding-mobile;

    @include desktop() {
        margin-left: $main-padding-desktop;
        margin-right: $main-padding-desktop;
    }

    @include wide() {
        margin-left: $main-padding-wide;
        margin-right: $main-padding-wide;
    }
}

@mixin main-bleed() {
    margin-left: -$main-padding-mobile;
    margin-right: -$main-padding-mobile;

    @include desktop() {
        margin-left: -$main-padding-desktop;
        margin-right: -$main-padding-desktop;
    }

    @include wide() {
        margin-left: -$main-padding-wide;
        margin-right: -$main-padding-wide;
    }

    @include max-width-breakpoint() {
        margin-left: calc(-1 * (50vw - $max-content-width/2));
        margin-right: calc(-1 * (50vw - $max-content-width/2));
    }    
}

@mixin full-width() {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin grid-container($columns: $grid-columns) {
    display: grid;
    grid-template-columns: repeat(#{$columns}, 1fr);
    gap: var(--grid-gutter);
}

$column-width: math.div($grid-width - (($grid-columns - 1) * $grid-gutter-desktop), $grid-columns);

@mixin grid-layout() {
    @include tablet() {    
        @for $i from 1 through $grid-columns {
            &.layout--span-#{$i} {
                max-width: $i * $column-width + ($i - 1) * $grid-gutter-desktop;
            }
        }

        &.layout--left {
            margin-left: 0;
            margin-right: auto;
        }

        &.layout--center {
            margin-left: auto;
            margin-right: auto;
        }

        &.layout--right {
            margin-left: auto;
            margin-right: 0;
        }
    }
}

@mixin grid-columns() {
    @for $i from 1 through $grid-columns {
        &.layout--span-#{$i} {
            grid-column: auto / span #{$i};
        }
    }

    &.layout--new-row {
        grid-column-start:  1;
    }
}

@mixin grid-span($span, $align: 'center') {
    @include tablet() { 
        max-width: $span * $column-width + ($span - 1) * $grid-gutter-desktop;

        @if $align == 'left' {
            margin-left: 0;
            margin-right: auto;
        } @else if $align == 'center' {
            margin-left: auto;
            margin-right: auto;
        } @else if $align == 'right' {
            margin-left: auto;
            margin-right: 0;
        }
    }
}
