.bg--red {
    background-color: (var(--red));
    border-radius: 3px;
    padding: 30px;

    @include tablet() {
        padding: 40px;
    }
}

.bg--red-50 {
    background-color: (var(--red-50));
    border-radius: 3px;
    padding: 30px;

    @include tablet() {
        padding: 40px;
    }
}

.bg--red-20 {
    background-color: (var(--red-20));
    border-radius: 3px;
    padding: 30px;

    @include tablet() {
        padding: 40px;
    }
}

.bg--green {
    background-color: (var(--green));
    border-radius: 3px;
    padding: 30px;

    @include tablet() {
        padding: 40px;
    }
}

.bg--green-50 {
    background-color: (var(--green-50));
    border-radius: 3px;
    padding: 30px;

    @include tablet() {
        padding: 40px;
    }
}

.bg--green-20 {
    background-color: (var(--green-20));
    border-radius: 3px;
    padding: 30px;

    @include tablet() {
        padding: 40px;
    }
}

.bg--black {
    background-color: (var(--black));
    border-radius: 3px;
    padding: 30px;

    @include tablet() {
        padding: 40px;
    }
}

.bg--black-50 {
    background-color: (var(--black-50));
    border-radius: 3px;
    padding: 30px;

    @include tablet() {
        padding: 40px;
    }
}

.bg--black-20 {
    background-color: (var(--black-20));
    border-radius: 3px;
    padding: 30px;

    @include tablet() {
        padding: 40px;
    }
}
