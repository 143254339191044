.ce_headline {
    &.background-headline {
        color: $textLight;
        background-color: var(--green-50);
        margin: 0;
        padding: 30px;
        border-radius: 3px;

        @include tablet() {
            padding: 40px;
        }
    }

}