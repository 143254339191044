@import "~normalize.css/normalize.css";

/* global settings */
@import './settings/_colors.scss'; @import './settings/_layout.scss';

/* functions and mixins */
@import './functions/_breakpoints.scss'; @import './functions/_grid.scss'; @import './functions/_mixins.scss'; @import './functions/_styles.scss';

/* base styles settings */
@import './base/_basic.scss'; @import './base/_typography.scss';

/* styles of various components */
@import './components/_backgrounds.scss'; @import './components/_fonts.scss'; @import './components/_image-shadow.scss'; @import './components/_image-shape.scss'; @import './components/_link-box.scss'; @import './components/_red-button.scss'; @import './components/_red-title.scss';

/* section styles */
@import './sections/_footer.scss'; @import './sections/_header.scss'; @import './sections/_main.scss'; @import './sections/_menu.scss'; @import './sections/_wrapper.scss';

/* module styles */
@import './modules/_mod_article.scss'; @import './modules/_mod_configurator_list.scss'; @import './modules/_mod_customnav.scss'; @import './modules/_mod_html_burger.scss'; @import './modules/_mod_html_header_footer.scss'; @import './modules/_mod_html_header_logo.scss'; @import './modules/_mod_navigation.scss';

/* element styles */
@import './elements/_ce_form_configurator.scss'; @import './elements/_ce_form_personal-data.scss'; @import './elements/_ce_headline.scss'; @import './elements/_rsce_advantages.scss'; @import './elements/_rsce_components.scss'; @import './elements/_rsce_fullscreen-image.scss'; @import './elements/_rsce_image_with_text.scss'; @import './elements/_rsce_intro_image.scss'; @import './elements/_rsce_intro.scss';
