/**
 * Breakpoints (Note: they are set up to be mobile first)
 */
$tablet: 800px;
$desktop: 1140px;
$menu: 800px;
$larger: 1400px;
$wide: 1600px;


/**
 * Grid
 */
$grid-width: 1120px;
$grid-gutter-desktop: 20px;
$grid-gutter-mobile: 12px;
$grid-columns: 12;


/**
 * Text size
 */
$text-size-desktop: 19px;
$text-size-mobile: 16px;


/**
 * Font families
 */


/**
 * Misc
 */
$base-mobile: 390px;
$base-desktop: 1366px;
$max-content-width: 1166px;
$max-width: 1366px;
$main-padding-mobile: 30px;
$main-padding-desktop: 40px;
$main-padding-wide: 40px;