.logo-header {
    z-index: 20;

    .logo {
        width: 130px;
    }

    @include menu() {
        .logo {
            width: 200px;
        }
    }
}
