.mod_navigation {
    ul {
        @include listreset();
        font-size: 1.4rem;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        gap: 5px;

        li {
            text-align: center;

            > * {
                display: block;
                padding: 10px;
            }
        }

        a {
            text-decoration: none;
        }

        strong {
            color: $textLight;
            border-radius: 3px;
            box-sizing: border-box;
            background-color: var(--green-50);
        }
    }

    @include menu() {
        ul {
            flex-direction: row;
            gap: vwmax(10px);
        }
    }
}