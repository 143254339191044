#footer {
    @include fwbg($textLight);

    & > .inside {
        display: flex;
        gap: 30px;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 30px 0;
    }

    @include tablet() {
        & > .inside {
            height: vwmax(104px);
            justify-content: space-between;
            flex-direction: row;
            max-width: $max-content-width;
            margin-left: auto;
            margin-right: auto;
            padding: 0;
        }
    }
}
