.ce_rsce_intro-image {
  @include main-bleed();
  margin-top: 0;
  margin-bottom: 0;
  position: relative;

  .image_container img {
    margin: 0 auto;
  }

  .headline-container, .text-container {
    color: white;
    position: absolute;
    z-index: 9;
    width: 45%;
    height: 50%;
    left: 50%;
    display: flex;
    align-items: center;

    h1, p {
      margin: 0;
    }
  }

  &.text--top-left {
    .headline-container {
      transform: translate(-100%, 0);
      text-align: right;
      justify-content: flex-end;
    }
    .text-container {
      top: 50%;
    }
  }

  &.text--top-right {
    .text-container {
      top: 50%;
      transform: translate(-100%, 0);
      justify-content: right;
    }
  }

  &.text--centered {
    .headline-container {
      width: 100%;
      justify-content: center;
      left: 0;
    }
    .text-container {
      width: 100%;
      top: 50%;
      left: 0;
      justify-content: center;
    }
  }

  &.text--bottom-left {
    .headline-container {
      top: 50%;
      transform: translate(-100%, 0);
      text-align: right;
      justify-content: flex-end;
    }
    .text-container {
      left: 50%;
    }
  }

  &.text--bottom-right {
    .headline-container {
      top: 50%;
      left: 50%;
    }
    .text-container {
      justify-content: right;
      transform: translate(-100%, 0);
    }
  }
}
