#burger {
    cursor: pointer;
    user-select: none;
    z-index: 20;

    .burger__line {
        width: 22px;
        height: 2px;
        border-radius: 2px;
        background-color: black;
        margin: 6px 0;
        transition: 0.2s;
    }

    body.menu-open & {

        .burger__line--2 {
            opacity: 0;
        }

        .burger__line--1 {
            transform: translate(0, 8px) rotate(-45deg);
        }

        .burger__line--3 {
            transform: translate(0, -8px) rotate(45deg);
        }
    }
}