#menu {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: visibility 0.2s linear, opacity 0.2s linear;
    z-index: 15;
    
    .inside {
        background-color: white;
        @include main-padding();
        padding-top: 80px;
        padding-bottom: 30px;
    }
    
    body.menu-open & {
        visibility: visible;
        opacity: 1;
    }
}
