.ce_rsce_fullscreen-image {
    @include main-bleed();
    position: relative;

    .image_container {
        img {
            max-height: 456px;
            width: 100%;
            object-fit: cover
        }
    }

    .text {
        @include h2();
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: white;
    }
}
