.ce_rsce_image_with_text {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        .image_container {
            @include image-shadow();
        }
    }

    @include tablet() {
        gap: 60px;
    }
}

.ce_rsce_image_with_text.img--left {
    @include tablet() {
        flex-direction: row-reverse;
    }
}

.ce_rsce_image_with_text.img--right {
    @include tablet() {
      flex-direction: row;
    }
}
